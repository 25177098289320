<template>
  <div class="card p-1" :style="`font-family:${font},sans-serif`">
    <!-- <div>
      <p>{{ this.rowData }}</p>
    </div> -->
    <div class="title head">
      <p class="h2" style="color: #558cef">รายละเอียดวัสดุรับเข้า</p>
    </div>
    <hr />
    <div class="row">
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="เลขทะเบียน:">
        <b-input id="idg" v-model="this.rowData.material_code" type="text" disabled />
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="หมายเลขวัสดุ:">
        <b-input id="idg" v-model="this.rowData.m_id" type="text" disabled />
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="ชื่อวัสดุ:">
        <b-input id="idg" v-model="this.rowData.m_name" type="text" disabled />
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="วันที่-เดือน-ปี:">
        <b-form-datepicker
          v-model="this.rowData.admitdate"
          :date-format-options="{
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }"
          locale="th-TH"
          disabled
        />
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="ปีงบประมาณ:">
        <b-input id="idg" v-model="this.rowData.budget_year" type="text" disabled />
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="จำนวน:">
        <b-input id="idg" v-model="this.rowData.amount" type="text" disabled />
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="หน่วยนับ:">
        <b-input id="idg" v-model="this.rowData.unittype" type="text" disabled />
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="ราคา:">
        <b-input id="idg" v-model="this.rowData.unitprice" type="text" disabled />
      </b-form-group>
      <b-form-group class="col-12 col-md-4 text-Primary" label-cols="3" label-cols-lg="3" label="ราคา + vat 7%:">
        <b-input id="idg" v-model="this.rowData.unitprice_vat" type="text" disabled />
      </b-form-group>
      <!-- <div class="col-12">
        <b-button
          class="ml-1"
          variant="primary"
          @click="$router.push({ name: 'outputwassadu' })"
          >เบิก</b-button
        >
      </div> -->
    </div>
    <div>
      <hr />
      <div class="h2" style="color: #558cef">ประวัติรายการวัสดุ</div>
      <hr />
      <!-- search input -->
      <div class="custom-search row justify-content-end">
        <b-form-group class="col-12 col-md-6">
          <div class="d-flex align-items-center">
            <label class="mr-1">ค้นหา</label>
            <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
            <div>
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <b-button variant="outline-primary"
                    ><feather-icon icon="ShareIcon" class="mr-50" />Export
                    <feather-icon icon="ChevronDownIcon" class="mr-50"
                  /></b-button>
                </template>

                <b-dropdown-item @click="exportExcel">
                  <span> <feather-icon icon="FileIcon" class="mr-50" /> Excel</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <b-overlay :show="show" rounded="sm">
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          :line-numbers="true"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.field === 'fullName'" class="text-nowrap">
              <b-avatar :src="props.row.avatar" class="mx-1" />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5', '10', '15', '25', '50', '100']"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage1(value))"
                />
                <span class="text-nowrap">of {{ total }} entries</span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="handlePagechange1($event)"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import API from "@/views/connectDB/condb.js";
import _ from "lodash";
export default {
  components: {
    VueGoodTable,
  },
  props: {
    rowData: Object,
  },
  data() {
    return {
      show: false,
      total: "",
      pageLength: 5,
      currentPage: 1,
      dir: false,
      columns: [
        {
          label: "วันที่-เดือน-ปี",
          field: "req_date",
        },
        {
          label: "เลขทะเบียน",
          field: "material_code",
        },

        {
          label: "รายการ",
          field: "taskname",
        },
        {
          label: "จำนวน",
          field: "req_amount",
        },
        {
          label: "กลุ่มงาน",
          field: "work_gname",
        },
        {
          label: "ฝ่ายงาน",
          field: "dep_name",
        },
      ],
      rows: [],
      searchTerm: "",
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      font: "",
    };
  },
  computed: {
    example: {
      cache: false,
      get() {
        return Date.now() + this.msg;
      },
    },
  },
  created() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    _.isEmpty(this.rowData) ? this.$router.push({ path: "/search-for-materials" }) : true;
  },
  mounted() {
    this.get_data_table();
  },
  methods: {
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: "รายละเอียดวัสดุรับเข้า",
        columns: [
          {
            title: "รายละเอียดวัสดุรับเข้า",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "วันที่-เดือน-ปี",
                field: "req_date",
              },
              {
                title: "เลขทะเบียน",
                field: "material_code",
              },

              {
                title: "รายการ",
                field: "taskname",
              },
              {
                title: "จำนวน",
                field: "req_amount",
              },
              {
                title: "กลุ่มงาน",
                field: "work_gname",
              },
              {
                title: "ฝ่ายงาน",
                field: "dep_name",
              },
            ],
          },
        ],
      });
    },
    handlePagechange1(event) {
      this.currentPage = event;
      this.get_data_table();
    },
    handleChangePage1(event) {
      this.pageLength = event;
      this.get_data_table();
    },
    async get_data_table() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url_a = `${API}materialSearchTableExamineHistory?m_id=${this.rowData.m_id}`;
      const url = `${API}materialSearchTablePickupHistory?m_id=${this.rowData.m_id}&material_code=${this.rowData.material_code}&_page=${this.currentPage}&_limit=${this.pageLength}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      const res_a = await axios.get(url_a, head);
      // console.log(res.data.message.data);
      const res_in = res_a.data.message.data.map((el_in) => ({
        ...el_in,
        req_date: `${parseInt(el_in.admitdate.split("-")[2])} ${this.month[parseInt(el_in.admitdate.split("-")[1])]} ${
          Number(el_in.admitdate.split("-")[0]) + 543
        }`,
        req_amount: el_in.amount,
      }));
      const res_out = res.data.message.data.map((el_out) => ({
        ...el_out,
        req_date: `${parseInt(el_out.req_date.split("-")[2])} ${this.month[parseInt(el_out.req_date.split("-")[1])]} ${
          Number(el_out.req_date.split("-")[0]) + 543
        }`,
      }));
      // console.log(res_in);
      // console.log(res_out);
      this.rows = res_in.concat(res_out);
      this.total = res.data.message.total;
      this.show = false;
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>

<style></style>
